import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <>
          <a style={{ float: 'right' }} href="http://twopm.studio">🍵 TwoPM Studios</a>
          <h1
            style={{
              ...scale(1.5),
              marginBottom: rhythm(1.5),
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
        </>
      )
    } else {
      header = (
        <>
          <a style={{ float: 'right' }} href="http://twopm.studio">🍵 TwoPM Studios</a>
          <h3
            className="header-home-link"
            style={{
              fontFamily: `Fira Sans, sans-serif`,
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h3>
        </>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()} <a href="https://twopm.studio">TwoPM Studios</a> 🍵, <small>Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a></small>
        </footer>
      </div>
    )
  }
}

export default Layout
